<template>
  <div></div>
</template>
<script setup lang="ts">
import { driver, type Config, type DriveStep, type Driver, type PopoverDOM } from 'driver.js'
import 'driver.js/dist/driver.css'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'
import { useUiStore } from '~/store/ui'

const TYPES_CONFIG = {
  [TOURS_TYPES.NEW_FEATURE]: {
    mainTitle: '🚀 New Feature!',
  },
  [TOURS_TYPES.UPDATE]: {
    mainTitle: '🚀 Update!',
  },
  [TOURS_TYPES.INIT_USER]: {
    mainTitle: '🚀 Welcome aboard!',
  },
}

const props = withDefaults(
  defineProps<{
    steps?: DriveStep[]
    tour: TOURS_IDS
    highlight?: DriveStep
    config?: Config
    type?: TOURS_TYPES
  }>(),
  {
    steps: () => [],
    highlight: undefined,
    config: undefined,
    type: TOURS_TYPES.NEW_FEATURE,
  }
)

const emit = defineEmits<{
  (e: 'destroyed'): void
}>()

const isUpdateTour = computed(() => props.type === TOURS_TYPES.UPDATE)

const driverObj = ref<Driver | null>(null)

const user = useUser()

const setupBasicStyle = (popover: PopoverDOM) => {
  popover.wrapper.classList.add('!max-w-[273px]', '!rounded-2xl', '!p-4')

  popover.closeButton.innerHTML = '✕'
  popover.closeButton.classList.add(
    '!top-4',
    '!right-4',
    '!size-5',
    '!text-lg',
    '!leading-4',
    '!hover:text-black-100',
    '!block'
  )

  popover.description.classList.add('!text-black-80', '!text-body-2', '!mt-2', '!font-sans')

  popover.footer.classList.remove('driver-popover-footer')
  popover.footer.classList.add('mt-4')

  const buttonClasses = [
    'flex',
    'w-fit',
    'select-none',
    'flex-row',
    'items-center',
    'justify-center',
    'gap-x-[4px]',
    'border-[1.5px]',
    'border-solid',
    'transition-colors',
    'duration-200',
    'disabled:pointer-events-none',
    'rounded-lg',
    '!font-sans',
    'h-8',
    'py-1',
    'text-subhead-3',
    ...(isUpdateTour.value ? ['basis-full'] : ['basis-6/12']),
  ]

  popover.previousButton.classList.add(
    ...buttonClasses,
    'border-primary-100',
    'text-primary-100',
    'hover:border-primary-120',
    'hover:bg-primary-05',
    'hover:text-primary-120',
    'focus-visible:outline-none',
    'active:bg-primary-10',
    'disabled:border-black-40',
    'disabled:bg-white',
    'disabled:text-black-40'
  )
  if (popover.previousButton.style.display === 'block') {
    popover.previousButton.style.display = 'flex'
  }

  popover.nextButton.classList.add(
    ...buttonClasses,
    'border-primary-100',
    'bg-primary-100',
    'text-white',
    'hover:border-primary-120',
    'hover:bg-primary-120',
    'focus-visible:outline-none',
    'active:border-primary-120',
    'active:bg-primary-120',
    'disabled:border-black-10',
    'disabled:bg-black-10',
    'disabled:text-black-40',
    ...(isUpdateTour.value ? ['!ml-0'] : ['!ml-2'])
  )
  if (popover.nextButton.style.display === 'block') {
    popover.nextButton.style.display = 'flex'
  }
}

const startTour = () => {
  if (driverObj.value && props.highlight) {
    driverObj.value?.highlight({
      ...props.highlight,
      popover: {
        nextBtnText: 'Learn more',
        prevBtnText: 'Dismiss',
        showButtons: ['next', 'previous', 'close'],
        onPopoverRender: (popover) => {
          setupBasicStyle(popover)

          popover.title.innerHTML = `
            <div class="flex flex-col pb-2 border-b-[1.5px] border-solid border-black-10">
                <b class="text-black-100 text-subhead-2 !font-sans">${TYPES_CONFIG[props.type].mainTitle}</b> 
                <p class="!text-black-80 !text-body-2 ml-5 mt-0.5">${props.highlight?.popover?.title}</p
            </div>`
        },
        onCloseClick: () => {
          driverObj.value?.destroy()
        },
        onPrevClick: () => {
          driverObj.value?.destroy()
        },
        onNextClick: () => {
          driverObj.value?.drive()
        },
        ...props.highlight.popover,
      },
    })
  } else {
    driverObj.value?.drive()
  }
}

const toggleTourInterface = () => {
  const popover = document.querySelector('#driver-popover-content')

  if (popover) {
    popover.classList.toggle('!hidden')
  }
}

defineExpose({
  driverObj,
  startTour,
  toggleTourInterface,
})

const uiStore = useUiStore()

onMounted(() => {
  driverObj.value = driver({
    steps: props.steps,
    stageRadius: 12,
    stagePadding: 4,
    allowClose: false,
    disableActiveInteraction: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: `
      Next
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="pointer-events:none !important">
        <path d="M12.5 5L19.5 12L12.5 19M5.5 12H18.5H5.5Z" stroke="currentColor" stroke-width="1.5" style="pointer-events:none !important" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `,
    prevBtnText: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="pointer-events:none !important">
        <path d="M19 12H6M12 5L5 12L12 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" style="pointer-events:none !important" stroke-linejoin="round"/>
      </svg>
      Previous
    `,
    onPopoverRender: (popover) => {
      setupBasicStyle(popover)

      popover.title.classList.add('!text-black-100', '!font-sans', '!text-subhead-2')
    },
    onCloseClick: () => {
      driverObj.value?.destroy()
    },
    onDestroyed: async () => {
      await useUpdateTourStatus(props.tour)

      await useGetProfile()

      emit('destroyed')

      nextTick(() => {
        uiStore.cleanTour()
      })
    },
    ...props.config,
  })

  if (driverObj.value && user) {
    setTimeout(() => {
      startTour()
    }, 1000)
  }

  useMittListen('tour:start', () => {
    startTour()
  })
})

onUnmounted(() => {
  if (driverObj.value) {
    driverObj.value.destroy()
  }

  useMittRemove('tour:start')
})
</script>
